import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export const FormGroup = ({
  label,
  type = "text",
  placeholder,
  id,
  value,
  help,
  icon,
  classes,
  input_classes,
  handleType,
}) => (
  <div className={`form-group ${classes}`}>
    <label htmlFor={id}>
      {icon ? <i className={`${icon} mr-1`}></i> : null}
      {label}
    </label>{" "}
    <input
      type={type}
      className={`form-control ${input_classes}`}
      id={id}
      placeholder={placeholder}
      value={value}
      onChange={handleType}
    />{" "}
    {help && (
      <small id={`${id}Help`} className="form-text text-muted">
        {help}
      </small>
    )}
  </div>
);

export const InputGroup = ({
  label,
  type = "text",
  placeholder,
  id,
  value,
  help,
  classes,
  input_classes,
  badge,
  handleType,
  clear,
}) => (
  <div className={`form-group ${classes}`}>
    <label htmlFor={id}>{label}</label>{" "}
    <div className={`input-group ${clear && "has-clearable"}`}>
      {badge && (
        <label className="input-group-prepend" htmlFor={id}>
          <span className="badge">{badge}</span>
        </label>
      )}
      {clear && (
        <button
          type="button"
          className={`close ${value && "show"}`}
          data-id={id}
          onClick={clear}
        >
          <span aria-hidden="true">
            <i className="fa fa-times-circle"></i>
          </span>
        </button>
      )}
      <input
        type={type}
        className={`form-control ${input_classes}`}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={handleType}
        autoComplete="chrome-off"
      />{" "}
    </div>
    {help && (
      <small id={`${id}Help`} className="form-text text-muted">
        {help}
      </small>
    )}
  </div>
);
export const TextArea = ({
  label,
  placeholder,
  id,
  value,
  help,
  classes,
  input_classes,
  handleType,
  disabled,
}) => (
  <div className={`form-group ${classes}`}>
    <label htmlFor={id}>{label}</label>
    <textarea
      className={`form-control ${input_classes}`}
      id={id}
      placeholder={placeholder}
      rows="3"
      spellCheck="false"
      value={value}
      onChange={handleType}
      disabled={disabled}
    ></textarea>
    {help && (
      <small id={`${id}Help`} className="form-text text-muted">
        {help}
      </small>
    )}
  </div>
);
export const Select = ({
  label,
  placeholder,
  id,
  value,
  help,
  classes,
  input_classes,
  options,
  handleChange,
}) => (
  <div className={`form-group ${classes}`}>
    <label htmlFor={id}>{label}</label>
    <select
      className={`form-control ${input_classes}`}
      id={id}
      onChange={handleChange}
      value={value}
    >
      {placeholder && <option value="false"> {placeholder} </option>}
      {options &&
        options.map((item, key) => (
          <option value={item.value} key={`option${item.value}`}>
            {" "}
            {item.text || item.value}{" "}
          </option>
        ))}
    </select>
    {help && (
      <small id={`${id}Help`} className="form-text text-muted">
        {help}
      </small>
    )}
  </div>
);

export const SelectAndText = ({
  options,
  placeholder,
  id,
  help,
  button,
  icon = "fas fa-search",
  handleChange,
  selectValue,
  typeValue,
  onKeyDown,
}) => {
  const [dropdownOptions, setDropdownoptions] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [dateValue, setDateValue] = useState(
    startDate.toLocaleString("en-GB").split(",")[0] +
      " - " +
      (endDate ? endDate.toLocaleString("en-GB").split(",")[0] : "")
  );

  useEffect(() => {
    setDateValue(
      startDate.toLocaleString("en-GB").split(",")[0] +
        " - " +
        (endDate ? endDate.toLocaleString("en-GB").split(",")[0] : "")
    );
    if (
      options.filter(
        ({ value, type }) => value === selectValue && type === "daterange"
      ).length
    ) {
      handleChange({
        target: {
          id,
          value:
            startDate.toLocaleString("en-GB").split(",")[0] +
            " - " +
            (endDate || startDate).toLocaleString("en-GB").split(",")[0],
        },
      });
      if (endDate) {
        onKeyDown({ keyCode: 13 });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    const filterDropdown = options.find(
      ({ value, type }) => value === selectValue && type === "dropdown"
    );
    if (filterDropdown) {
      setDropdownoptions(filterDropdown.options);
    }

    handleChange({
      target: {
        id,
        value: "",
      },
    });
    setStartDate(new Date());
    setEndDate(null);
    setDateValue(
      startDate.toLocaleString("en-GB").split(",")[0] +
        " - " +
        (endDate ? endDate.toLocaleString("en-GB").split(",")[0] : "")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectValue]);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const CutomDatePickerInput = ({ onClick }) => (
    <input
      type="text"
      className="form-control"
      id={id}
      onClick={onClick}
      placeholder={help}
      value={dateValue}
      readOnly
      autoComplete="chrome-off"
    />
  );
  return (
    <div className="input-group input-group-alt">
      <div className="input-group-prepend">
        <select
          className="custom-select"
          id={`select_${id}`}
          onChange={handleChange}
          value={selectValue}
        >
          {placeholder && <option value="false"> {placeholder} </option>}
          {options &&
            options.map((item, key) => (
              <option
                value={item.value || item.txt}
                key={`options_${id}${key}`}
              >
                {item.txt || item.value}
              </option>
            ))}
        </select>
      </div>

      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">
            <i className={icon}></i>
          </span>
        </div>
        {options.filter(
          ({ value, type }) => value === selectValue && type === "daterange"
        ).length ? (
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            customInput={<CutomDatePickerInput />}
            shouldCloseOnSelect={!Boolean(endDate)}
          />
        ) : options.filter(
            ({ value, type }) => value === selectValue && type === "dropdown"
          ).length && dropdownOptions ? (
          <select
            className="form-control"
            id={id}
            onChange={handleChange}
            value={typeValue}
            onKeyDown={onKeyDown}
          >
            {dropdownOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        ) : (
          <input
            type="text"
            className="form-control"
            id={id}
            placeholder={help}
            onChange={handleChange}
            value={typeValue}
            autoComplete="chrome-off"
            onKeyDown={onKeyDown}
          />
        )}
        {button && (
          <div className="input-group-append">
            <button
              className={`btn ${button.class}`}
              type="button"
              onClick={button.click}
            >
              {button.txt}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export const CheckboxGroup = ({
  title,
  options = [],
  hadnleCheck,
  iniline = false,
  type,
}) => (
  <div className="form-group">
    <label>{title}</label>
    {options.map((item) => (
      <div
        className={`custom-control custom-checkbox ${
          iniline ? "custom-control-inline" : ""
        }`}
        key={item.id || item.value}
      >
        <input
          onChange={hadnleCheck}
          type="checkbox"
          data-type={type}
          defaultChecked={item.checked}
          className="custom-control-input"
          id={item.id || item.value}
          disabled={item.disabled}
          value={item.id || item.value}
        />
        <label className="custom-control-label" htmlFor={item.id || item.value}>
          {item.txt || item.value || item.descrp}
        </label>
      </div>
    ))}
  </div>
);

export const Autocomplete = ({
  label,
  id,
  value,
  suggestions,
  handleChange,
  handleSelect,
}) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (value && value.length > 0 && suggestions.length > 1) {
      setLoading(true);
      const filtered = suggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(value.toLowerCase())
      );
      setLoading(false);
      if (filtered[0] === value) {
        setShowSuggestions(false);
      } else {
        setFilteredSuggestions(filtered);
        setShowSuggestions(true);
      }
    } else {
      setShowSuggestions(false);
    }
  }, [value, suggestions]);

  const handleSuggestionClick = (suggestion) => {
    handleSelect(suggestion);
    setShowSuggestions(false);
  };

  return (
    <div className="form-group">
      <label htmlFor={id}>{label}</label>
      <input
        type="text"
        className="form-control"
        id={id}
        value={value}
        onChange={handleChange}
        autoComplete="off"
      />
      {loading && <div className="spinner-border" role="status"></div>}
      {showSuggestions && !loading && (
        <ul className="list-group position-absolute w-100">
          {filteredSuggestions.slice(0, 5).map((suggestion, index) => (
            <li
              key={index}
              className="list-group-item hover hover_bg"
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
