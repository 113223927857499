import React, { useEffect, useState } from "react";
import firebase, { db } from "../../../middleware/firebase";
import { Header } from "../components/side_components";
import { FormGroup, TextArea, Autocomplete } from "../components/forms";
import { Toast } from "../components/alerts";
import { ModalDefault } from "../components/modals";
import {
  joinByComma,
  getProductUrl,
} from "../../../middleware/common-functions";
import _ from "lodash";

const IdeaSection = ({ ideaData, disSelect, fetchIdeas }) => {
  const [idea, setIdea] = useState(_.cloneDeep(ideaData));
  const [modal, setModal] = useState(false);
  const [toast, setToast] = useState({});

  useEffect(() => {
    setIdea(_.cloneDeep(ideaData));
  }, [ideaData]);

  const handleType = (ev, index) => {
    const newIdea = { ...idea };
    const field = ev.target.id;
    const newValue = ev.target.value;

    if (field === "images") {
      newIdea.images[index] = newValue;
    } else {
      newIdea[field] = newValue;
    }

    setIdea(newIdea);
  };

  const handleSelect = (newValue) => {
    const newIdea = { ...idea };
    newIdea.category = newValue;
    setIdea(newIdea);
  };

  const addImage = () => {
    const newIdea = { ...idea };
    newIdea.images.push("");
    setIdea(newIdea);
  };

  const deleteImage = (index) => {
    const newIdea = { ...idea };
    newIdea.images.splice(index, 1);
    setIdea(newIdea);
  };

  const getProducts = async (idparts) => {
    const productsCollection = db.collection("products");

    let products = [];
    try {
      for (const idpart of idparts) {
        const snapshot = await productsCollection
          .where("idpart", "==", idpart)
          .get();
        snapshot.forEach((doc) => {
          const productData = doc.data();
          const product = {
            url: getProductUrl(productData),
            name: productData.descp1,
            id: productData.id,
            idpart: productData.idpart,
          };
          products.push(product);
        });
      }
      return products;
    } catch (error) {
      console.log("idea.js:21 | error", error);
    }
  };

  const saveChanges = async (ev) => {
    const collection = db.collection("ideas");
    const button = ev.target;
    button.classList.add("disabled");
    button.textContent = "Guardando...";
    const ideaData = { ...idea };
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    ideaData.timestamp = timestamp;

    if (typeof ideaData.idparts === "string" && ideaData.idparts.trim()) {
      ideaData.idparts = ideaData.idparts.split(",").map((x) => x.trim());
    }
    const products = await getProducts(ideaData.idparts);
    ideaData.products = products;

    try {
      if (idea.id !== "new") {
        await collection.doc(idea.id).set({ ...ideaData });
      } else {
        delete ideaData.id;
        const newIdea = await collection.add({ ...ideaData });
        const newId = newIdea.id;
        const ideaRef = { ...idea };
        ideaRef.id = newId;
        setIdea(ideaRef);
      }

      button.classList.remove("disabled");
      button.textContent = "Guardar";
      fetchIdeas();
    } catch (error) {
      console.log("idea.js:61 | error", error);
      button.classList.remove("disabled");
      button.textContent = "Guardar";
    }
  };

  const removeDocument = async (ev) => {
    const button = ev.target;
    button.classList.add("disabled");
    button.textContent = "Eliminando...";
    const collection = db.collection("ideas").doc(idea.id);

    try {
      await collection.delete();
      disSelect();
      fetchIdeas();
    } catch (error) {
      console.log("idea.js:86 | error", error);
      button.classList.remove("disabled");
      button.textContent = "Eliminar";
    }
  };

  const toggleModal = async () => {
    setModal(!modal);
  };

  return (
    <div className="page-sidebar bg-light p-3">
      <Header title={idea.title ? idea.title : ""} />

      <div className="card">
        <div className="card-body">
          <FormGroup
            handleType={handleType}
            value={idea.title}
            id="title"
            label="Título"
          />
          <Autocomplete
            handleChange={handleType}
            value={idea.category || ""}
            handleSelect={handleSelect}
            id="category"
            label="Categoría"
            suggestions={[
              "Cocina",
              "Patio",
              "Recamara",
              "Terraza",
              "Sala",
              "Cochera",
              "Baño",
            ]}
          />
          <TextArea
            label="Productos"
            help="Separar números de parte por coma. Ej: 542154, 545421"
            placeholder="Pega aquí los números de parte separados por comas"
            value={idea.idparts ? joinByComma(idea.idparts) : ""}
            handleType={handleType}
            id="idparts"
          />
          {idea.images && idea.images.length ? (
            idea.images.map((imgLink, index) => (
              <div key={index} className="d-flex align-items-end">
                <FormGroup
                  classes={"w-75"}
                  handleType={(ev) => handleType(ev, index)}
                  value={imgLink}
                  id="images"
                  label={`Imagen ${index + 1}`}
                />
                <button
                  className="btn btn-block btn-warning w-25 m-3"
                  onClick={() => {
                    deleteImage(index);
                  }}
                >
                  Borrar Imagen
                </button>
              </div>
            ))
          ) : (
            <p>No se han agregado imagenes</p>
          )}
          <button className="btn btn-block btn-primary" onClick={addImage}>
            Agregar Imagen
          </button>
          <br />
          <br />
          <div className="row justify-content-between">
            <div className="col-md-3">
              <button
                className="btn btn-block btn-outline-dark"
                onClick={disSelect}
              >
                Cancelar
              </button>
            </div>
            <div className="col-md-6">
              <button
                className="btn btn-block btn-success"
                onClick={saveChanges}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
      {idea.id !== "new" && (
        <button
          className="btn btn-block btn-link text-danger"
          onClick={toggleModal}
        >
          Eliminar
        </button>
      )}
      <ModalDefault
        show={modal ? true : false}
        toggle={toggleModal}
        actionFun={removeDocument}
        title={idea.title ? `¿Borrar Idea ${idea.title}?` : "¿Borrar Idea?"}
        action_btn="danger"
        action_txt="Borrar Código"
      >
        <p>Esta acción no se puede revertir</p>
      </ModalDefault>
      <Toast
        show={toast.txt ? true : false}
        txt={toast.txt}
        type={toast.type || "success"}
        toggle={() => setToast(false)}
      />
    </div>
  );
};

export default IdeaSection;
