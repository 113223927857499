import React from "react";
import { Link } from "react-router-dom";

export const ButtonNested = ({ txt, classes, options, clickFun, btn }) => {
  return (
    <div
      className="btn-group"
      role="group"
      aria-label="Button group with nested dropdown"
    >
      <button
        type="button"
        className={`btn ${btn} ${classes}`}
        onClick={clickFun}
      >
        {txt}
      </button>
      <div className="btn-group" role="group">
        <button
          id="btnGroupDrop2"
          type="button"
          className={`btn dropdown-toggle ${btn}`}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        ></button>
        <div className="dropdown-menu" aria-labelledby="btnGroupDrop2">
          <div className="dropdown-arrow"></div>
          {options.map((item, key) => {
            return (
              <button
                className="dropdown-item"
                key={key}
                onClick={item.click || clickFun}
                data-value={item.value}
              >
                {item.name}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const SimpleDropdown = ({
  txt,
  classes,
  options,
  clickFun,
  btn,
  disabled = false,
}) => {
  return (
    <div className="btn-group" role="group" aria-label="Dropdown button">
      <button
        type="button"
        className={`btn dropdown-toggle ${btn} ${classes}`}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={clickFun}
        disabled={disabled}
      >
        <span className="algo-aglo">{txt + " "}</span>
      </button>
      <div className="dropdown-menu">
        <div className="dropdown-arrow"></div>
        {options.map((item, key) => (
          <button
            className="dropdown-item"
            key={key}
            onClick={item.click || clickFun}
            data-value={item.value}
          >
            {item.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export const RichButton = ({
  icon,
  title,
  desc,
  to,
  bgColor,
  clickFun,
  showButton,
  classes,
}) => {
  let textColor = "";
  switch (bgColor) {
    case "dark":
    case "blue":
    case "success":
      textColor = "text-light";
      break;
    default:
      textColor = "";
  }

  return (
    <div className={`card card-figure ${classes}`}>
      <div className="card-body p-0">
        <figure className="figure">
          <div className={`text-center bg-${bgColor || "dark"}`}>
            <i className={`${textColor} ${icon} icon`}></i>
          </div>
          {to ? (
            <Link to={to}>
              <figcaption className="figure-caption">
                <h6 className="figure-title"> {title}</h6>
                <p className="text-muted mb-0"> {desc} </p>
              </figcaption>
            </Link>
          ) : (
            <figcaption className="figure-caption">
              <h6 className="figure-title"> {title}</h6>
              <p className="text-muted mb-0"> {desc} </p>
            </figcaption>
          )}
        </figure>
      </div>
      {clickFun && (
        <div className="card-footer">
          <button
            className={`mt-2 btn btn-block btn-primary ${clickFun.class}`}
            onClick={clickFun.fun}
          >
            {clickFun.txt}
          </button>
        </div>
      )}
      {showButton && !clickFun && to ? (
        <div className="card-footer">
          <Link to={to} className="mt-2 btn btn-block btn-primary">
            {showButton}
          </Link>
        </div>
      ) : null}
    </div>
  );
};
